<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.PARTNER_USER") }}
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <p class="lead">
            {{ $t("GENERAL.PARTNER_USER_INFO") }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
export default {
  name: "you-are-not-partner-info",
};
</script>
