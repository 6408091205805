<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.CHANGE_PASSWORD") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button type="submit" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          {{ $t("GENERAL.SAVE") }}
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          {{ $t("GENERAL.CANCEL") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"> {{ $t("GENERAL.CURRENT_PASSWORD") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              value=""
              :placeholder="$t('GENERAL.CURRENT_PASSWORD')"
              name="current_password"
              ref="current_password"
            />
            <a href="#" class="text-sm font-weight-bold" v-if="false">{{ $t("AUTH.FORGOT_PASSWORD") }}</a>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">{{ $t("GENERAL.NEW_PASSWORD") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              :placeholder="$t('GENERAL.NEW_PASSWORD')"
              name="new_password"
              ref="new_password"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">{{ $t("GENERAL.CONFIRM_PASSWORD") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              :placeholder="$t('GENERAL.CONFIRM_PASSWORD')"
              name="verify_password"
              ref="verify_password"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      status: "",
      valid: true,
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");
    let self = this;
    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: self.$t("VALIDATION.REQUIRED", {
                field: self.$t("GENERAL.PASSWORD"),
              }),
            },
          },
        },
        new_password: {
          validators: {
            notEmpty: {
              message: self.$t("VALIDATION.REQUIRED", {
                field: self.$t("GENERAL.NEW_PASSWORD"),
              }),
            },
          },
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: self.$t("VALIDATION.REQUIRED", {
                field: self.$t("GENERAL.CONFIRM_PASSWORD"),
              }),
            },
            identical: {
              compare: function () {
                return password_change_form.querySelector('[name="new_password"]').value;
              },
              // eslint-disable-next-line prettier/prettier
              message: self.$t("VALIDATION.CONFIRM_PASSWORD_MATCH")
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton(),
      },
    });

    this.fv.on("core.form.valid", () => {
      let current_password = this.$refs.current_password.value;
      let new_password = this.$refs.new_password.value;
      let verify_password = this.$refs.verify_password.value;
      const submitButton = this.$refs["kt_save_changes"];

      // set spinner to submit button
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch("auth/" + UPDATE_USER, {
          payload: {
            current_password,
            new_password,
            verify_password,
          },
          suffix: "/password",
        })
        .then(() => {
          self.cancel();
          TheToaster.success("GENERAL.DATA_SAVED");
        })
        .finally(() => {
          submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("VALIDATION.WRONG_DATA"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
      });
    });
  },
  methods: {
    save() {
      this.fv.validate();
    },
    cancel() {
      this.fv.resetForm();
      this.$refs.current_password.value = "";
      this.$refs.new_password.value = "";
      this.$refs.verify_password.value = "";
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
  },
};
</script>

<style scoped></style>
