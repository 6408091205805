<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.PARTNER_USER") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          {{ $t("GENERAL.SAVE") }}
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          {{ $t("GENERAL.CANCEL") }}
        </button>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">
              {{ $t("GENERAL.ACCOUNT_CONFIGURATION") }}
            </h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"></label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{
                  backgroundImage: `url(${userWhiteLabelPicture})`,
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("GENERAL.SLUG") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="surname" :placeholder="$t('GENERAL.SLUG')" class="form-control form-control-lg" type="text" v-model="modelSlug" />
            <span class="form-text text-justify text-danger">{{ $t("GENERAL.SLUG_INFO") }}</span>
          </div>
        </div>
        <!--begin::Form Group-->
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("GENERAL.PARTNER_URL") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-globe"></i>
                </span>
              </div>
              <input
                type="text"
                name="whiteLabelLink"
                id="whiteLabelLink"
                class="form-control form-control-lg form-control-solid"
                ref="whiteLabelLink"
                readonly
                disabled
                :value="whiteLabelLink"
                placeholder="Email"
              />
              <div class="input-group-append">
                <button class="btn btn-success" @click="copyToClipboard">
                  <i class="fa fa-copy"></i>
                </button>
              </div>
            </div>
            <span class="form-text text-justify">{{ $t("GENERAL.PARTNER_URL_INFO") }}</span>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("GENERAL.NAME") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" ref="name" v-model="model.name" :placeholder="$t('GENERAL.NAME')" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("GENERAL.COMMISSION") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg form-control-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-money-bill-wave"></i>
                </span>
              </div>
              <input
                readonly
                disabled
                type="text"
                class="form-control form-control-lg form-control-solid"
                ref="name"
                v-model="currentCommission"
                :placeholder="$t('GENERAL.COMMISSION')"
              />
            </div>
            <span class="form-text text-justify">{{ $t("GENERAL.COMMISSION_INFO") }}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{ $t("GENERAL.DISCOUNT") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg form-control-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-percent"></i>
                </span>
              </div>
              <input
                readonly
                disabled
                type="text"
                class="form-control form-control-lg form-control-solid"
                ref="name"
                v-model="currentDiscount"
                :placeholder="$t('GENERAL.DISCOUNT')"
              />
            </div>
            <span class="form-text text-justify">{{ $t("GENERAL.DISCOUNT_INFO") }}</span>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import TheToaster from "@/core/services/the-toaster";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import LAUtils from "@/core/services/LAUtils.service";

export default {
  name: "AccountInformation",
  data() {
    return {
      model: {
        name: "",
        slug: "",
        commission: 0.0,
        discount: 0.0,
      },
    };
  },
  mounted() {
    this.cancel();
  },
  methods: {
    copyToClipboard() {
      let copyText = document.getElementById("whiteLabelLink");
      copyText.select();
      copyText.setSelectionRange(0, 9999999);
      document.execCommand("copy");
      TheToaster.success("GENERAL.COPIED");
    },
    save() {
      let white_label_name = this.model.name;
      let white_label_slug = LAUtils.slugIt(this.model.slug);

      this.$store
        .dispatch("auth/" + UPDATE_USER, {
          payload: {
            white_label_name,
            white_label_slug,
          },
          suffix: "/partner",
        })
        .then(() => {
          TheToaster.success("GENERAL.DATA_SAVED");
        });
    },
    cancel() {
      this.model.slug = this.currentUser.white_label_slug;
      this.model.name = this.currentUser.white_label_name;
      this.model.commission = this.currentUser.white_label_commission;
      this.model.discount = this.currentUser.white_label_global_discount;
    },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", userWhiteLabelPicture: "auth/userWhiteLabelPicture" }),
    modelSlug: {
      get() {
        return this.model.slug;
      },
      set(value) {
        this.model.slug = LAUtils.slugIt(value);
      },
    },

    whiteLabelLink() {
      // eslint-disable-next-line prettier/prettier
      return (window.location.origin + "/#/store/" + this.model.slug);
    },
    currentCommission() {
      // eslint-disable-next-line prettier/prettier
      let c = this.model.commission || 0;
      return (parseFloat(c) * 100).toFixed(2) + "%";
    },
    currentDiscount() {
      // eslint-disable-next-line prettier/prettier
      let c = this.model.discount || 0;
      return (parseFloat(c) * 100).toFixed(2) + "%";
    },
  },
};
</script>

<style scoped></style>
