<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("GENERAL.ACCOUNT_CONFIGURATION") }}
        </h3>
        <span class="font-weight-bold font-size-sm mt-1 text-danger"> * {{ $t("GENERAL.WE_DONT_SHARE") }}</span>
      </div>
      <div class="card-toolbar">
        <button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
          {{ $t("GENERAL.SAVE") }}
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          {{ $t("GENERAL.CANCEL") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">
              {{ $t("GENERAL.MY_DATA") }}
            </h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"></label>
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{
                  backgroundImage: `url(${currentUserPicture})`,
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">
            {{ $t("GENERAL.ID") }}
          </label>
          <div class="col-lg-9 col-xl-6">
            <input readonly disabled ref="hash" class="form-control form-control-lg form-control-solid text-muted" type="text" v-bind:value="currentUser.id" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"> {{ $t("GENERAL.HASH") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              readonly
              disabled
              ref="hash"
              class="form-control form-control-lg form-control-solid text-muted"
              type="text"
              v-bind:value="currentUser.hash"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"> {{ $t("GENERAL.NAME") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="name" class="form-control form-control-lg" type="text" v-model="model.name" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"> {{ $t("GENERAL.SURNAME") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input ref="surname" :placeholder="$t('GENERAL.SURNAME')" class="form-control form-control-lg" type="text" v-model="model.surname" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.BIRTH_DATE") }}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="birth_date"
              class="form-control form-control-lg"
              type="text"
              :placeholder="$t('GENERAL.DATE_FORMAT')"
              v-model="model.birth_date"
              v-mask="'##/##/####'"
            />
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">
              {{ $t("GENERAL.CONTACT") }}
            </h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.COUNTRY") }}</label>
          <div class="col-lg-9 col-xl-6">
            <Compcountryselector
              class=" "
              name="country-select"
              v-model="model.country"
              language="en-US"
              :aria-placeholder="$t('GENERAL.SELECT_COUNTRY')"
              ref="country"
            ></Compcountryselector>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.PHONE") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone"
                type="text"
                class="form-control form-control-lg"
                :placeholder="$t('GENERAL.PHONE')"
                v-model="model.phone"
                v-mask="['(###) #####-####']"
              />
            </div>
            <span class="form-text text-muted">We'll never share your email with anyone else.</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.EMAIL") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input ref="email" type="text" class="form-control form-control-lg" :placeholder="$t('GENERAL.EMAIL')" v-model="model.email" />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.PARTNER") }}</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg form-control-solid">
              <div class="input-group-prepend form-control-solid">
                <span class="input-group-text">
                  <i class="la flaticon2-tag"></i>
                </span>
              </div>
              <input
                readonly
                disabled
                ref="preferred_white_label"
                type="text"
                class="form-control form-control-lg form-control-solid text-muted"
                :placeholder="$t('GENERAL.PARTNER')"
                v-model="model.preferred_white_label"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import Compcountryselector from "@/view/pages/components/CompCountrySelector/CompCountrySelector";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import TheToaster from "@/core/services/the-toaster";

export default {
  directives: { mask },
  components: { Compcountryselector },
  name: "PersonalInformation",
  data() {
    return {
      model: {
        id: "",
        hash: "",
        name: "",
        surname: "",
        birth_date: "",
        country: "",
        phone: "",
        email: "",
        preferred_white_label: "",
      },
    };
  },
  mounted() {
    this.cancel();
  },
  methods: {
    save() {
      let name = this.model.name;
      let surname = this.model.surname;
      let birth_date = this.model.birth_date;
      let country = this.model.country;
      let phone = this.model.phone;
      let email = this.model.email;
      this.$store
        .dispatch("auth/" + UPDATE_USER, {
          payload: {
            name,
            surname,
            birth_date,
            country,
            phone,
            email,
          },
          suffix: "",
        })
        .then(() => {
          TheToaster.success("GENERAL.DATA_SAVED");
        });
    },
    cancel() {
      this.model.id = this.currentUser.id;
      this.model.hash = this.currentUser.hash;
      this.model.name = this.currentUser.name;
      this.model.surname = this.currentUser.surname;
      this.model.birth_date = this.currentUser.birth_date;
      this.model.country = this.currentUser.country;
      this.model.phone = this.currentUser.phone;
      this.model.email = this.currentUser.email;
      this.model.preferred_white_label = this.currentUser.preferred_white_label;
    },
    // onFileChange(e) {
    //   const file = e.target.files[0];
    //
    //   if (typeof FileReader === "function") {
    //     const reader = new FileReader();
    //
    //     reader.onload = (event) => {
    //       this.current_photo = event.target.result;
    //     };
    //
    //     reader.readAsDataURL(file);
    //   } else {
    //     alert("Sorry, FileReader API not supported");
    //   }
    // },
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", currentUserPicture: "auth/currentUserPicture" }),
  },
};
</script>
