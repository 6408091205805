<template>
  <div class="CompCountrySelector">
    <b-form-select
      v-model="currentValue"
      :options="options"
      class="form-control form-control-lg mb-3"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  name: "Compcountryselector",
  props: {
    value: String,
  },
  computed: {
    currentValue: {
      get() {
        return this.selectedCountry;
      },
      set(value) {
        this.selectedCountry = value;
      },
    },
  },
  watch: {
    selectedCountry(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.selectedCountry = value;
    },
  },
  mounted() {
    let self = this;
    let countries = Object.entries(this.$t("COUNTRIES"));
    countries.map((item) => {
      self.options.push({ item: item[0], name: item[1] });
    });
    this.currentValue = this.value;
  },
  data() {
    return {
      selectedCountry: "",
      options: [],
    };
  },
};
</script>
