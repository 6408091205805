<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ title }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button @click="showFileChooser" class="mr-3 mb-3 btn-outline-info font-weight-bold">
          <i class="fa fa-upload" aria-hidden="true"></i>
          {{ $t("GENERAL.CHOOSE_IMAGE") }}
        </b-button>
        <b-button @click="saveImage" class="mr-3 mb-3 btn-success ofont-weight-bold">
          <i class="fa fa-crop" aria-hidden="true"></i>
          {{ $t("GENERAL.SEND") }}
        </b-button>
      </div>
    </div>
    <input ref="input" type="file" name="image" accept="image/*" @change="setImage" />
    <div class="card card-custom content">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8 border-light bordered rounded">
            <div class="img-cropper">
              <vue-cropper
                v-if="showCropper"
                ref="cropper"
                :aspectRatio="1"
                :initialAspectRatio="1"
                :minCropBoxWidth="100"
                :minCanvasWidth="200"
                :minContainerWidth="200"
                :src="imgSrc"
                preview=".preview"
                class="cropper-min-height"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div style="min-height: 209px">
              <div class="w-100 cropped-img mt-2 preview secondary border-light bordered rounded" />
            </div>
            <div v-if="false">
              <div class="cropped-image secondary">
                <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                <div v-else class="crop-placeholder" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-12">
            <div class="actions">
              <b-button-group horizontal class="mr-3 mb-3">
                <b-button @click="zoom(0.2)">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                </b-button>
                <b-button @click="zoom(-0.2)">
                  <i class="fa fa-search-minus" aria-hidden="true"></i>
                </b-button>
              </b-button-group>

              <b-button-group horizontal class="mr-3 mb-3">
                <b-button @click="move(-10, 0)">
                  <i class="fa fa-caret-left" aria-hidden="true"></i>
                </b-button>
                <b-button @click="move(10, 0)">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </b-button>
              </b-button-group>

              <b-button-group horizontal class="mr-3 mb-3">
                <b-button @click="move(0, -10)">
                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                </b-button>
                <b-button @click="move(0, 10)">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </b-button>
              </b-button-group>

              <b-button-group horizontal class="mr-3 mb-3">
                <b-button @click="rotate(90)">
                  <i class="fa fa-share" aria-hidden="true"></i>
                </b-button>
                <b-button @click="rotate(-90)">
                  <i class="fa fa-reply" aria-hidden="true"></i>
                </b-button>
              </b-button-group>

              <b-button @click="reset" class="mr-3 mb-3">
                <i class="fa fa-arrows-alt" aria-hidden="true"></i>
              </b-button>

              <b-button @click="download" class="mr-3 mb-3" v-if="false">
                <i class="fa fa-download" aria-hidden="true"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "PhotoUrlCropper",
  components: {
    VueCropper,
  },
  props: {
    endPoint: String,
    title: String,
  },

  data() {
    return {
      imgSrc: "media/stock-600x400/img-9.jpg",
      cropImg: "",
      fileExtension: "jpg",
      showCropper: false,
    };
  },
  mounted() {
    // ser;
  },
  methods: {
    saveImage() {
      this.cropImage();
      // let extention = this.fileExtension;
      let image = this.cropImg;

      this.$store
        .dispatch("auth/" + UPDATE_USER, {
          payload: {
            image,
          },
          suffix: this.endPoint,
        })
        .then(() => {
          TheToaster.success("GENERAL.DATA_SAVED");
        });
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 300,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL();
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setImage(e) {
      this.showCropper = false;
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();
      if (file.type.indexOf("image/") === -1) {
        TheToaster.info("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
        this.showCropper = true;
      } else {
        TheToaster.error("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    download() {
      if (this.cropImg) {
        let extention = this.fileExtension;
        let href = this.cropImg;
        let link = document.createElement("a");
        link.addEventListener(
          "click",
          function () {
            link.href = href;
            link.download = "cropper-img." + extention;
          },
          false
        );
        link.click();
      } else {
        TheToaster.info("Please, crop image before download it");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
input[type="file"] {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
}

.actions {
  margin-top: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px);
  overflow: hidden;
}

.cropped-image img {
  max-width: 100%;
}

.cropper-bg {
  background-repeat: repeat;
}

.cropper-min-height {
  min-height: 400px;
}
</style>
